import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="intro">
      <div className="intro-container">
        <h1>404</h1>
        <p>Not found. <Link to="/">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
